<template>
  <div>
    <!-- Chatbot Container -->
    <div class="chatbot-container" :class="{ expanded: isChatOpen }">
      <!-- Chatbot Header (Toggle Button) -->
      <div
          class="chatbot-header d-flex flex-row justify-content-between searching"
          :class="{ searching: isLoading || isTyping }"
          @click="toggleChat"
      >
        <h3>
          <span class="ai-loader"></span>
          I'm <strong>SAI</strong> . Your smart AI Assistant!
          <span v-if="!isChatOpen">Click to get started</span>
          <span v-else>Ready for you ... </span>
          <span v-if="isLoading"> Thinking 🤔...</span>
        </h3>
        <i class="fa fa-angle-up" v-if="!isChatOpen"></i>
        <i class="fa fa-angle-down" v-else></i>
      </div>

      <!-- Chatbot Body (Visible only when isChatOpen is true) -->
      <div v-if="isChatOpen" class="chatbot-body">
        <!-- Chatbot Messages -->
        <div class="chatbot-messages" ref="chatMessages">
          <!-- Default Message from Sai -->
          <div v-if="showDefaultMessage" class="ai">
            <div class="message-content">
              <p>Hello, my name is Sai. Ask me anything & I'll be glad to help!</p>
            </div>
          </div>

          <!-- User and AI Messages -->
          <div v-for="(message, index) in chatMessages" :key="index" :class="message.type">
            <div class="message-content">
              <p>{{ message.text }}</p>
              <!-- Display product data if available -->
              <div v-if="message.products" class="item-list">
                <div v-for="product in message.products" :key="product.id" class="product-item">
                  <img :src="product.image_url" :alt="product.name" class="product-image"/>
                  <div class="product-details">
                    <router-link :to="product.link" class="product-name">
                      {{ product.name }}
                    </router-link>
                    <p class="product-price">{{ product.price }}</p>
                    <p class="product-location">{{ product.location }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Typing Indicator -->
          <div v-if="isLoading" class="ai">
            <div class="message-content"></div>
          </div>
        </div>

        <!-- Chatbot Input -->
        <div class="chatbot-input">
          <input
              v-model="userInput"
              type="text"
              placeholder="Ask anything! eg nissan note in Kiambu ..."
              @keyup.enter="sendQuery"
              :disabled="isLoading"
              class="user-input"
          />
          <button @click="sendQuery" :disabled="isLoading" class="send-button">
            <i class="fas fa-paper-plane" v-if="!isLoading"></i>
            <span class="loading-pulse" v-else></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex';

export default {
  name: 'SaiComponent',
  data() {
    return {
      userInput: '', // User's input
      isLoading: false, // Loading state
      isTyping: false, // Typing indicator state
      showDefaultMessage: true, // Show default message on initial load
      isMobile: false, // Track if the device is mobile
    };
  },
  computed: {
    ...mapGetters({
      chatMessages: 'listings/chatMessages',
      isChatOpen: 'listings/isChatOpen',
    }),
    isDesktop() {
      return !this.isMobile;
    },
  },
  watch: {
    // Watch for route changes
    '$route': {
      handler() {
        this.checkIfMobile()
        // Collapse the chatbot when the route changes
        if (this.isMobile) {
          this.setChatOpen(false);
        }
      },
      immediate: true, // Trigger the handler immediately on component creation
    },
  },
  mounted() {
    // Check if the device is mobile on mount
    this.checkIfMobile();
    // Set isChatOpen to true if on desktop
    if (this.isDesktop) {
      this.setChatOpen(true);
    }
  },
  methods: {
    ...mapActions({
      addMessage: 'listings/addMessage',
      toggleSaiChat: 'listings/toggleChat',
      setChatOpen: 'listings/setChatOpen',
    }),
    // Check if the device is mobile
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 600; // Adjust breakpoint as needed
    },
    async queryBackendAPI(search) {
      const apiUrl = 'https://v1.sokoni.ke/api/ai-search'; // Replace with your API endpoint
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({search}),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const data = await response.json();

      // Return the nested "response" object from the API
      return data.data.response;
    },
    async sendQuery() {
      if (!this.userInput.trim() || this.isLoading) return;

      this.showDefaultMessage = false;
      await this.addMessage({type: 'user', text: this.userInput});
      this.scrollToBottom();
      const search = this.userInput;
      this.userInput = '';
      this.isLoading = true;

      try {
        const response = await this.queryBackendAPI(search);
        this.isTyping = true;
        await this.typeMessage(response.message, response.data);
      } catch (error) {
        await this.addMessage({type: 'ai', text: 'Sorry, something went wrong. Please try again.'});
      } finally {
        this.isLoading = false;
        this.isTyping = false;
      }

      // Scroll to the bottom after sending the query
      this.scrollToBottom();
    },
    async typeMessage(message, products) {
      let typedMessage = '';
      const messageIndex = this.chatMessages.length;

      // Add the initial message with empty text
      await this.addMessage({type: 'ai', text: '', products: []});

      for (let i = 0; i < message.length; i++) {
        typedMessage += message[i];
        // Update the existing message
        this.chatMessages[messageIndex].text = typedMessage;
        await this.delay(25);

        // Scroll to the bottom as the message is being typed
        this.scrollToBottom();
      }

      // Update the existing message with the final text and products
      this.chatMessages[messageIndex].text = message;
      this.chatMessages[messageIndex].products = products;

      // Scroll to the bottom after typing is done
      this.scrollToBottom();
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const chatMessages = this.$refs.chatMessages;
        if (chatMessages) {
          chatMessages.scrollTop = chatMessages.scrollHeight;
        }
      });
    },
    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    toggleChat() {
      this.toggleSaiChat();
      if (this.isChatOpen) {
        this.$nextTick(() => this.scrollToBottom());
      }
    },
  },
};
</script>

<style scoped>
/* Chatbot Container */
.chatbot-container {
  width: 400px; /* Default width for desktop */
  max-width: 90%; /* Ensure it doesn't overflow on small screens */
  max-height: 60px; /* Initial height to fit only the header */
  border: 1px solid #ccc;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 20px; /* Default bottom position */
  right: 20px; /* Position on the right side */
  transition: max-height 0.3s ease-in-out, border-radius 0.3s ease-in-out;
  overflow: hidden; /* Hide overflowing content when collapsed */
  z-index: 99;
}

/* Expanded State */
.chatbot-container.expanded {
  max-height: 70vh; /* Expand to 70% of viewport height */
  border-radius: 10px 10px 10px 10px;
}

/* Desktop-specific styles */
@media (min-width: 601px) {
  .chatbot-container {
    bottom: 0; /* Remove bottom space on desktop */
  }

  .chatbot-container.expanded {
    max-height: 100vh; /* Expand to full viewport height on desktop */
    border-radius: 10px 10px 0 0; /* Remove rounded corners at the bottom */
  }
}

/* Chatbot Header (Toggle Button) */
.chatbot-header {
  padding: 15px;
  background-color: var(--theme-color);
  color: white;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden; /* For border animation */
}

/* Border Animation */
.chatbot-header::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 12px; /* Slightly larger to cover the border */
  background: linear-gradient(45deg, #00ffcc, #007bff, #00ffcc, #007bff);
  background-size: 400% 400%;
  z-index: 1;
  animation: glowingBorder 3s linear infinite;
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease-in-out;
}

.chatbot-header:hover::before {
  opacity: 1; /* Show border animation on hover */
}

@keyframes glowingBorder {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.chatbot-header h3 {
  position: relative;
  z-index: 2; /* Ensure text is above the gradient */
}

.chatbot-header:hover {
  background-color: #0056b3;
}

/* Loading Pulse Animation */
.loading-pulse {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ffffff;
  animation: pulse 1.5s infinite;
  margin-left: 10px;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    opacity: 0.7;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(0.8);
    opacity: 0.7;
  }
}

/* Chatbot Body */
.chatbot-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* Enable scrolling inside the body */
  max-height: calc(70vh - 120px); /* Adjust height to account for header and input */
}

/* Chatbot Messages */
.chatbot-messages {
  flex: 1;
  padding: 15px;
  overflow-y: auto; /* Enable scrolling for messages */
  background-color: #fff;
  scroll-behavior: smooth;
}

/* Default Message */
.ai .message-content {
  color: black;
  padding: 10px;
  border-radius: 10px;
}

.user .message-content {
  border-radius: 10px;
  background: #eeeeee;
  padding: 10px;
}

/* Product List */
.item-list {
  margin-top: 10px;
}

.product-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border-top: thin solid #eeeeee;
}

.product-image {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-right: 10px;
}

.product-details {
  flex: 1;
}

.product-name {
  font-weight: bold;
  color: #007bff;
  text-decoration: none;
}

.product-name:hover {
  text-decoration: underline;
}

.product-price {
  margin: 5px 0;
  color: #28a745;
}

.product-location {
  margin: 0;
  color: #6c757d;
}

/* Chatbot Input */
.chatbot-input {
  display: flex;
  padding: 10px;
  background-color: #f9f9f9;
  border-top: 1px solid #ccc; /* Add a border to separate input from messages */
  position: sticky;
  bottom: 0;
  z-index: 1; /* Ensure input stays above messages */
}

.chatbot-input input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
  background: unset !important;
  border: unset !important;
}

.chatbot-input button {
  padding: 10px 15px;
  background-color: var(--theme-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100px;
}

.chatbot-input button:hover {
  background-color: #0056b3;
}

/* Mobile Responsiveness */
@media (max-width: 600px) {
  .chatbot-container {
    width: 90%; /* Full width on small screens */
    right: 5%; /* Center horizontally */
    left: 5%;
    bottom: 60px; /* Adjust bottom position for mobile */
  }

  .chatbot-container.expanded {
    max-height: 70vh; /* Adjust height for mobile devices */
  }
}

.chatbot-header.searching::before {
  opacity: 1; /* Ensure the gradient border is visible */
  animation: glowingBorder 6.5s infinite; /* Faster animation for searching state */
}

@keyframes glowingBorder {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.chatbot-header::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 12px;
  background: linear-gradient(45deg, #00ffcc, #007bff, #00ffcc, #007bff);
  background-size: 400% 400%;
  z-index: 1;
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease-in-out;
}

.chatbot-header.searching::before {
  opacity: 1; /* Show the gradient border */
}


.ai-loader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
}

.ai-loader:before,
.ai-loader:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  animation: pulsOut 1.8s ease-in-out infinite;
  filter: drop-shadow(0 0 1rem rgba(255, 255, 255, 0.75));
}

.ai-loader:before {
  width: 100%;
  padding-bottom: 100%;
  box-shadow: inset 0 0 0 1rem #fff;
  animation-name: pulsIn;
}

.ai-loader:after {
  width: calc(100% - 2rem);
  padding-bottom: calc(100% - 2rem);
  box-shadow: 0 0 0 0 #fff;
}

@keyframes pulsIn {
  0% {
    box-shadow: inset 0 0 0 1rem #fff;
    opacity: 1;
  }
  50%, 100% {
    box-shadow: inset 0 0 0 0 #fff;
    opacity: 0;
  }
}

@keyframes pulsOut {
  0%, 50% {
    box-shadow: 0 0 0 0 #fff;
    opacity: 0;
  }
  100% {
    box-shadow: 0 0 0 1rem #fff;
    opacity: 1;
  }
}

</style>