import client from './client'

export default {
    async getCategories(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/categories', filters)));
    },
    async getAbuseCategories(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/abuse-categories', filters)));
    },
    async getFields(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/category-fields', filters)));
    },
    async getCategoryFilters(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/category-filters', filters)));
    },
    async getFieldValues(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/category-fields-values', filters)));
    },

    async getCategory(id) {
        return client.parseResponse(await client.get('/categories/' + id));
    },
}
