import client from './client'

export default {

    async getSeller(filters) {
        return client.parseResponse(client.parseResponse(await client.post('/seller-information', filters)))
    },

    async listings(filters) {
        return client.parseResponse(await client.get('/listings', filters))
    },
    async getListing(id) {
        return client.parseResponse(await client.get('/listings/' + id))
    },

    async generatePresignedUrl(data) {
        return client.parseResponse(await client.post('/presigned-url/', data))
    },
    async getListings(data) {
        return client.parseResponse(await client.get('/listings', data))
    },
    async getUserListings(data) {
        return client.parseResponse(await client.get('/user-listings', data))
    },
    async getUserListingSummary(data) {
        return client.parseResponse(await client.get('/user-listings-summary', data))
    },
    async createListing(data) {
        return client.parseResponse(await client.post('/add-listings', data))
    },
    async sendAbuseReport(data) {
        return client.parseResponse(await client.post('/report-listing', data))
    },
    async removeImage(data) {
        return client.parseResponse(await client.post('/remove-listing-image', data))
    },
    async addComment(data) {
        return client.parseResponse(await client.post('/add-comment', data))
    },
    async editListing(data) {
        return client.parseResponse(await client.post('/listings/' + data.id + '?_method=PUT', data))
    },
    async updateListingViews(property_id) {
        return client.parseResponse(await client.post('/update-listing-views/' + property_id))
    },
    async setNotificationUserHistory(data) {
        return client.parseResponse(await client.post('/notification-browse-data', data))
    },

    async getSimilarListings(filters) {
        return client.parseResponse(await client.get('/listings', filters))
    }

}
