<template>
  <button class="nav-link btn  border-top text-success" id="pills-out-tab" data-bs-toggle="pill"
          @click="logout"
          data-bs-target="#pills-out" type="button" role="tab" aria-selected="false">
    <i class="fa fa-lock"></i> &nbsp;
    Log out [{{ profile?.first_name }}]
  </button>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ElMessage } from 'element-plus'
import { googleLogout } from 'vue3-google-login'

export default {
  name: 'AccountLogoutComponent',
  data() {
    return {
      action: 'save',
      search: '',
      autoUpload: false,
      headers: {
        "Authorization: Bearer ": ""
      },
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters['profile/loggedIn'];
    },
    ...mapGetters({
      'profile': 'profile/user'
    })
  },
  methods: {
    ...mapActions({
      'getProfile': 'profile/getProfile',
      'logoutUser': 'profile/logout'
    }),
    getAccessToken() {
      return localStorage.getItem('app_token')
    },
    logout() {
      googleLogout()
      ElMessage.info("Logging you out ... ")
      this.logoutUser().then(() => {
        ElMessage.success("Logged out successfully!")
      })
    }
  },
  created() {
    this.getProfile()
  }
}
</script>
