import client from './client'

export default {

    async getPaymentStatus(filters) {
        return client.parseResponse(await client.post('/checkout-request-status', filters));
    },

    async getWallet(filters) {
        return client.parseResponse(await client.get('/wallet', filters));
    },

    async createPaymentRequest(data) {
        return client.parseResponse(await client.post('/initiate-payment', data))
    },

    async createWalletPaymentRequest(data) {
        return client.parseResponse(await client.post('/initiate-wallet-payment', data))
    },
    async createBusinessPaymentRequest(data) {
        return client.parseResponse(await client.post('/initiate-business-payment', data))
    },
}
