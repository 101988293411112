<template>
  <div>
    <div class="">
      <div class="d-flex flex-column">
        <SaiComponent/>
        <div class="d-md-none d-block mobile-menu mobile-cart">
          <ul>
            <li :class="getActiveClass('/')">
              <router-link to="/">
                <i class="iconly-Home icli"></i>
                <span>Home</span>
              </router-link>
            </li>

            <li :class="getActiveClass('categories')">
              <router-link to="/categories">
                <i class="iconly-Category icli js-link"></i>
                <span>Categories</span>
              </router-link>
            </li>
            <li class="shadow-lg rounded-3 circular" style="border-radius: 2px;">
              <router-link to="/add-listing">
                <i class="iconly-Plus icli fly-cate"></i>
                <span style="color:#d8ff00; font-weight: bold">Sell</span>
              </router-link>
            </li>
            <li :class="getActiveClass('search')">
              <router-link to="/search" class="search-box">
                <i class="iconly-Search icli"></i>
                <span>Search</span>
              </router-link>
            </li>
            <li :class="getActiveClass('search')">
              <router-link to="/dashboard" class="search-box">
                <i class="iconly-User2 icli"></i>
                <span>Profile</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import SaiComponent from "@/components/OpenAI/Sai.vue";

export default {
  name: 'MobileFixedMenu',
  components: {SaiComponent},
  methods: {
    getActiveClass(page) {
      if (page === '/') {
        return this.$route.path === page ? 'active' : ''
      } else {
        return this.$route.path.includes(page) ? 'active' : ''
      }
    }
  }
}
</script>
