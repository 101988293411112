import App from './App.vue'
import {createApp} from 'vue'
import router from './routes'
import store from './data/store'
import 'element-plus/dist/index.css'
import vue3GoogleLogin from 'vue3-google-login'
import {VueMasonryPlugin} from 'vue-masonry'
import {createMetaManager} from 'vue-meta';

createApp(App)
    .use(router)
    .use(store)
    .use(createMetaManager())
    .use(vue3GoogleLogin, {
        clientId: '992002134203-614usu42d3ubk11fgkctme25bar1ga3n.apps.googleusercontent.com'
    })
    .use(VueMasonryPlugin)
    .mount('#app')

