import router from '../../routes'
import api from '../api'
import {ElMessage} from 'element-plus'

export default {
    namespaced: true, state: {
        loading: false,
        sending_contribution_request: false,
        sending_report_email: false,
        show_wallet_modal: false,
        wallet: {},
        payments: {data: [], current_page: 1, total: 0, per_page: 10},
        payment: [],
        payment_request: {
            message: ''
        },
        checkout_message: '',
        payment_request_error: {
            message: ''
        },
        errors: {
            data: {
                errors: {
                    phone_number: [],
                    reference_no: [],
                    payment_date: [],
                    amount: [],
                    id_number: [],
                    tenant_id: [],
                    source_number: [],
                    value_date: [],
                    channel: [],
                    transaction_type: []
                }
            }
        },
        has_errors: false
    }, getters: {
        loading: state => state.loading,
        payment: state => state.payment,
        errors: state => state.errors,
        has_errors: state => state.has_errors,
        payment_request: state => state.payment_request,
        wallet: state => state.wallet,
        show_wallet_modal: state => state.show_wallet_modal
    }, mutations: {
        setShowWalletModal(state, val) {
            state.show_wallet_modal = val
        },
        setPaymentRequestResponse(state, val) {
            state.payment_request = val
        },
        setPaymentCheckoutResponse(state, val) {
            state.checkout_message = val
        },

        setWallet(state, val) {
            state.wallet = val
        },
        setLoading(state, val) {
            state.loading = val
        },
        setErrors(state, errors) {
            state.errors = errors
        }, hasErrors(state, val) {
            state.has_errors = val
            if (!val) {
                state.errors = {
                    data: {
                        errors: {
                            reference_no: [],
                            payment_date: [],
                            amount: [],
                            phone_number: [],
                            id_number: [],
                            tenant_id: [],
                            source_number: [],
                            value_date: [],
                            channel: [],
                            transaction_type: []
                        }
                    }
                }
            }
        }
    }, actions: {

        async createPaymentRequest({dispatch, commit}, data) {
            try {
                commit('hasErrors', false)
                commit('setLoading', true)
                const response = await api.createPaymentRequest(data)
                commit('setPaymentRequestResponse', response)
                commit('setLoading', false)
                ElMessage.success('Payment request initiated successfully!')
                dispatch('getPaymentStatus', {
                    'checkout_request_id': response?.data?.checkout_request_id
                })
            } catch (e) {
                commit('setLoading', false)
                commit('hasErrors', true)
                commit('setErrors', e.response)
                ElMessage.error('We\'re having trouble processing your payment request. Please try again later.')
            }
        },

        async createWalletPaymentRequest({dispatch, commit}, data) {
            try {
                commit('hasErrors', false)
                commit('setLoading', true)
                const response = await api.createWalletPaymentRequest(data)
                commit('setPaymentRequestResponse', response)
                commit('setLoading', false)
                ElMessage.success('Payment request initiated successfully!')
                dispatch('getPaymentStatus', {
                    'checkout_request_id': response?.data?.checkout_request_id,
                    'wallet': true
                })
            } catch (e) {
                commit('setLoading', false)
                commit('hasErrors', true)
                commit('setErrors', e.response)
                ElMessage.error('We\'re having trouble processing your payment request. Please try again later.')
            }
        },

        async createBusinessPaymentRequest({dispatch, commit}, data) {
            try {
                commit('hasErrors', false)
                commit('setLoading', true)
                const response = await api.createBusinessPaymentRequest(data)
                commit('setPaymentRequestResponse', response)
                commit('setLoading', false)
                ElMessage.success('Payment request initiated successfully!')
                dispatch('getPaymentStatus', {
                    'checkout_request_id': response?.data?.checkout_request_id,
                    'business': true
                })
            } catch (e) {
                commit('setLoading', false)
                commit('hasErrors', true)
                commit('setErrors', e.response)
                ElMessage.error('We\'re having trouble processing your payment request. Please try again later.')
            }
        },

        async getPaymentStatus({dispatch, commit}, data) {
            try {
                const timer = setInterval(async () => {
                    commit('setLoading', true)
                    commit('activateLoading', 'payments/loading', {root: true})
                    const response = await api.getPaymentStatus(data)
                    commit('setPaymentCheckoutResponse', response.message)
                    commit('setLoading', false)
                    if (response.status === 'Completed') {
                        ElMessage.success('Payment processed successfully.')
                        clearInterval(timer)
                        if (data?.wallet) {
                            dispatch('getWallet')
                            commit('setShowWalletModal', false)
                            commit('setPaymentRequestResponse', {
                                message: ""
                            })
                        } else if (data?.business) {
                            await router.replace('/business/catalogue')
                        } else {
                            await router.replace('/my-listings')
                        }

                    } else if (response.status === 'Failed') {
                        commit('setPaymentRequestResponse', response)
                        ElMessage.error('There was an issue processing your payment. Please try again later or contact support.')
                        clearInterval(timer)
                    }
                }, 5000)

            } catch (e) {
                commit('setLoading', false)
            }
        },

        async getWallet({commit}, filters) {
            commit('setLoading', true)
            try {
                const response = await api.getWallet(filters)
                commit('setWallet', response?.data)
                commit('setLoading', false)
                commit('hasErrors', false)
            } catch (e) {
                ElMessage.error(e.response.data.message)
                commit('setLoading', false)
                commit('hasErrors', true)
                commit('setErrors', e.response)
            }
        },
    }
}
