<template>
  <div class="video-container" ref="videoContainer">
    <!-- Image is shown first -->
    <img
        ref="thumbnail"
        :src="imageSrc"
        class="img-fluid lazyload"
        @mouseenter="playVideo"
        @touchstart="playVideo"
        @touchend="pauseVideo"
        alt=""/>

    <!-- Video loads only when needed -->
    <video
        ref="video"
        v-show="isLoaded"
        :src="videoSrc"
        v-if="videoSrc"
        loop
        muted
        playsinline
        preload="metadata"
        class="video-element"
        @mouseenter="playVideo"
        @mouseleave="pauseVideo"
        @touchstart="playVideo"
        @touchend="pauseVideo"
        @error="handleVideoError"
    ></video>
  </div>
</template>

<script>
export default {
  props: {
    videoSrc: String, // Video URL
    imageSrc: String // Image URL
  },
  data() {
    return {
      isLoaded: false, // Load video only when needed
      observer: null,
      isMobile: false // Flag to determine if the device is mobile
    }
  },
  mounted() {
    this.lazyLoadVideo();
    this.checkDeviceType(); // Determine device type
  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    lazyLoadVideo() {
      this.observer = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting && !this.isLoaded) {
              this.isLoaded = true; // Load video only when it enters viewport
              this.$refs.video.load();
              this.observer.disconnect();
            }
          },
          {threshold: 0.3} // Start loading when 30% of the video is visible
      );

      if (this.$refs.videoContainer) {
        this.observer.observe(this.$refs.videoContainer);
      }
    },
    handleVideoError() {
      this.videoSrc = ''; // Remove invalid video so the image remains
    },
    playVideo() {
      if (this.isLoaded && this.$refs.video) {
        this.$refs.video.style.opacity = '1';
        this.$refs.video.play();
      }
    },
    pauseVideo() {
      if (this.isLoaded && this.$refs.video) {
        this.$refs.video.style.opacity = '0';
        this.$refs.video.pause();
      }
    },
    checkDeviceType() {
      // Simple check for mobile devices (touch support)
      this.isMobile = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    }
  }
}
</script>

<style scoped>
.video-container {
  overflow: hidden;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  position: relative;
  will-change: transform, opacity;
}

.video-thumbnail,
.video-element {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.2s ease-in-out;
}

.video-element {
  opacity: 0;
  will-change: opacity;
}
</style>
